.columns-modal thead {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
}

.columns-modal tbody tr {
  padding: 10px;
  border: 1px solid #dcd6d6;
}

.columns-modal td {
  padding: 18px;
}

.columns-modal .modal-footer button {
  width: 114px;
  height: 36px;
}

#modify-columns {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  font-size: 16px;
  border-radius: 0;
  &:hover {
    font-family: $font-family-sans-serif-bold;
  }
}

.columns-modal .modal-footer {
  border: 0px;
}

.visibility-column {
  color: #585858;
}

.name-column {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

ul.task-fields-list {
  list-style: none;
}

.task-history {
  width: 100%;
}

.task-history-body {
  display: inline-block;
  width: 100%;

  thead {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #828282;
  }

  tbody tr {
    border: 1px solid #dcd6d6;
    box-sizing: border-box;
  }

  tbody tr td{
    padding: 20px;
  }

  thead tr th {
    padding: 15px;
  }

  button.task-history-refresh {
    float: right;
    margin-left: -2rem;
    margin-top: -2rem;
    padding: 0.2rem 0.25rem;

    &:hover {
      background-color: $gray-200;
    }

    &:active {
      background-color: $gray-400;
    }
    svg {
      height: 1.5em;
      width: 1.5em;
    }
  }
}
