@import "./variables";

@mixin mxxSmall {
  @media screen and (max-width: #{$bp1000 - 1px}) {
    @content;
  }
}

@mixin mxSmall {
  @media screen and (max-width: #{$bp1536 - 1px}) {
    @content;
  }
}

@mixin mxMedium {
  @media screen and (min-width: #{$bp1536}) and (max-width: #{$bp1920 - 1px}) {
    @content;
  }
}

@mixin mxLarge {
  @media screen and (min-width: #{$bp1920}) {
    @content;
  }
}