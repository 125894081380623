.table th {
  text-transform: capitalize;
}

.rt-resizable-header-content {
  font-family: $headings-font-family;
  padding: 0.5em 0;

  label {
    font-family: $font-family-sans-serif;
    margin-top: 0.3em;
    margin-bottom: 0;
  }
}

.rt-resizable-header-content:first-letter {
  text-transform: uppercase;
}

.ReactTable .rt-thead .rt-th {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #828282;
  border: 0;
}

.ReactTable .rt-th {
  min-width: 60px;
}

.ReactTable .rt-td {
  min-width: 60px;
}

.ReactTable .rt-thead .rt-th.row-header,
.ReactTable .rt-thead .rt-td.row-header,
.ReactTable .rt-tbody .rt-td.row-header,
.ReactTable .rt-tfoot .rt-td.row-header {
  font-family: $headings-font-family;
  text-align: left;
  padding-left: 1em;
}

.ReactTable .rt-resizer {
  width: 14px;
  right: -7px;
}

.ReactTable {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  border: 0;
}

.ReactTable .rt-tbody label {
  margin: 0px;
}

.ReactTable .rt-thead.-filters input[type="checkbox"],
.ReactTable input[type="checkbox"] {
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  background: white;
  outline: none;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 6px;
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

.ReactTable .rt-thead.-filters input:checked[type="checkbox"],
.ReactTable input:checked[type="checkbox"] {
  -webkit-appearance: checkbox;
}

.ReactTable .rt-thead.-header,
.ReactTable .rt-tbody .rt-tr-group,
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 1px solid $gray-300;
}

.ReactTable .rt-tr-group {
  flex: initial;
  padding: 0.7rem 0;
}

.ReactTable .rt-thead.-header,
.ReactTable .rt-tfoot {
  box-shadow: none;
}

.rt-thead.-header {
  background-color: rgba(247, 249, 252, 0.8);
}

.ReactTable .rt-thead.-header .rt-th,
.ReactTable .rt-thead.-header .rt-td {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-tfoot .rt-td {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

#table-area-container {
  height: calc(
    100vh - #{$topbar-height} - 80px
  ); //20px is for #table-area margin
  margin-left: $filters-width;
}

.ReactTable {
  height: 70vh;
}

.ReactTable button {
  border-radius: 0;
}

#table-area {
  margin: 20px;
  background: #ffffff;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  border-radius: 20px;
}

.under-table {
  margin: 1em 0;
  text-align: center;

  button {
    margin: 2.5rem 2rem;

    .button-text {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.2rem;
    }
  }
}

table.task-history thead {
  text-transform: capitalize;
}

table.task-history th,
table.task-history td {
  padding: 0.2em 1em;
  text-align: center;
  white-space: nowrap;
}

#tasks-header {
  min-height: 2.8em;
}

.right-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.modify-columns {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #0265b0;
}

.queue-item {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.task-cancel-button.btn {
  border: none;
  font-size: 1.5em;
  padding-top: 0;
  &:hover {
    color: red;
    background: none;
  }
}

.task-details-link {
  font-size: 1.8rem;
  margin-left: 1rem;
}
