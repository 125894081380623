@import "./breakpoints";

.landingPage {
  width: 100vw;
  height: 100vh;
  background-color: #EDF6FF;
  background-position: center center;
  position: relative;

  @include mxSmall {
    background-image: url("../../assets/img/landing/background_1440.svg");
  }

  @include mxMedium {
    background-image: url("../../assets/img/landing/background_1536.svg");
  }

  @include mxLarge {
    background-image: url("../../assets/img/landing/background_1920.svg");
  }

  .topbar {
    display: flex;
    justify-content: space-between;

    @include mxSmall {
      margin: 0 24px;
    }  

    @include mxMedium {
      margin: 0 48px;
    }  

    @include mxLarge {
      margin: 0 80px;
    }    

    .logos {
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .line {
        height: 40px;
        width: 1px;
        background-color: #00000066;
        margin: 0 1rem;
      }

      img {
        height: 40px;
      }
    }

    .loginButton {
      margin-top: 1.5rem;
      list-style-type: none;

      a {
        background-color: #106BEF;
        border-radius: 6px;
        color: #FFF;
        padding: .5rem 1rem;
      }
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h1 {
      font-size: 54px;
      font-weight: 800;
      color: #052048;
      margin-top: 4rem;
      max-width: 700px;

      @include mxLarge {
        font-size: 58px;
      }  

      &.without-margin {
        margin-top: 0;
      }
    }

    h3 {
      font-size: 28px;
      font-weight: 500;
      color: $gray-700;
      margin-top: 2rem;

      @include mxLarge {
        font-size: 32px;
      }  
    }

    .logo {
      margin-top: 6rem;
      height: 80px;

      @include mxLarge {
        height: 100px;
      }
    }
  }

  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
  
    svg:nth-child(2) {
      margin: 0 4rem;
    }
  }
}
