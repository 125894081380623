.pagination {
    font-weight: bold;
  }

.page-button-arrow {
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.20);
  border-radius: 6px;
  background: #F7F9FC;
}

.page-button {
  min-width: 24px;
  height: 24px;
  color: #464F60;
  font-size: 15px;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
}

.page-button:disabled {
  cursor: not-allowed;
  color: gray;
}

.page-button--active {
  font-weight: 600;
}
