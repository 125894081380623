.taskButton {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  border: none;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
}

