$primary-0: #EDEDFC;
$primary-50: #DFDEFC;
$primary-100: #D0CFFC;
$primary-200: #B9B6FA;
$primary-300: #9E9BF5;
$primary-400: #807CEA;
$primary-500: #5E5ADB;
$primary-600: #4945C4;
$primary-700: #3734A3;
$primary-800: #2A278F;
$primary-900: #151357;

$gray-0: #F7F9FC;
$gray-100: #f8f9fa;
$gray-120: #F7F8FA;
$gray-200: #f1f1f1;
$gray-300: #dcd6d6;
$gray-400: #868FA0;
$gray-500: #adb5bd;
$gray-600: #5A6376;
$gray-700: #464F60;
$gray-800: #343a40;
$gray-900: #212529;
$black: #191919;


$red: #e80808;
$light: $gray-300;
$dark: #171C26;

$selected: #edf5ff;
$primary: $primary-500;

$light-grey: #E9EDF5;

$background-color: rgba(247, 249, 252, 0.8);

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.125;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$badge-font-size: 85%;
$font-weight-bold: 700;

$headings-font-weight: 700;

$headings-font-family: "Inter", sans-serif; // for headers
$font-family-sans-serif: "Inter", sans-serif; // for normal text
$font-family-sans-serif-bold: "Inter", sans-serif; // for bold text

$border-radius: 0.5rem;
$border-radius-lg: 0.5rem;
$border-radius-sm: 0.5rem;

$nav-pills-border-radius: 0;
$nav-pills-link-active-bg: $primary-800;
$navbar-dark-color: $primary-0;
$navbar-dark-active-color: $primary-0;

$dropdown-spacer: 0;
$dropdown-padding-y: 0;
$dropdown-border-width: 0;
$dropdown-border-radius: 0;
$dropdown-item-padding-y: .5rem;

$grid-gutter-width: 14px;

$nav-link-padding-x: 0.6rem;
$nav-link-padding-y: 0.2rem;

$input-btn-padding-y-sm: 0.12rem;

$badge-font-weight: 400;

$tooltip-max-width: 200px;
$tooltip-color: $primary-200;
$tooltip-border-radius: 0.35rem;
$tooltip-padding-y: 0.65rem;
$tooltip-padding-x: 0.75rem;

$topbar-height: 56px;

// Breakpoints
$bp1000: 1000px;
$bp1536: 1536px;
$bp1920: 1920px;
