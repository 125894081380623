.task-field-name {
  text-transform: capitalize;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.task-field-value {
  font-weight: bold;
}

.task-fields-list {
  padding: 30px;
  border: 1px solid #dcd6d6;
  box-sizing: border-box;
  border-radius: 20px;
}

.modal-report-problem {
  background-color: transparent;
  font-size: 1.1rem;
  color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  margin: auto 0;
}