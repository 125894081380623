$filters-width: 260px;

.ReactTable .rt-thead.-filters {
  .rdt input {
    line-height: normal;
    height: auto;
  }

  .calendar-input-icon {
    margin-top: 0.5em;
    right: 2.5em;
    z-index: 100;
    position: absolute;
    pointer-events: none;
  }
}

.-filters {
  font-weight: 500;
  font-size: 14px;
}

.-filters input::placeholder {
  color: #777676;
}

.filters-panel {
  position: absolute;
  margin-left: 0 - $filters-width - 30px;
  min-width: 0 !important;
  background-color: white;
  padding-bottom: 2em;
  top: -70px;
  height: calc(94vh - 60px);
}

.filters-panel .rt-tr {
  width: $filters-width;
  display: block;
}

.filters-panel .rt-tr .rt-th {
  width: 100% !important;
  max-width: inherit !important;
  text-align: left !important;
}

.filters-panel .rt-tr .rt-th .filter-header {
  margin-top: 0.8em;
  margin-bottom: 0.6em;
  font-family: $headings-font-family;
  max-width: $filters-width - 70px;
  white-space: normal;
  font-weight: 700;
}

.filters-panel .rt-tr .rt-th input[type="checkbox"] {
  margin-right: 0.6em;
}

#filters-header {
  z-index: 2;
  margin-top: -20px;
  margin-bottom: -20px;
  width: $filters-width - 1px;
  position: absolute;
  margin-left: 0 - $filters-width - 30px;
  background-color: white;
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 138%;
  letter-spacing: 0.0625em;
  height: 6vh;

  color: #818181;
}

.badge-pill {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 10px 2px 10px;
}

.badge-light.badge-pill {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.filter-text {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}