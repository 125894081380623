.area {
  border: 1px solid $gray-300;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  border-radius: 5px;
}

.area .area-header {
  padding: 0.1rem 1rem;
  font-family: $headings-font-family;
  font-size: 1.1em;
}

.area .area-body {
  border-top: 3px solid $gray-200;
  padding: 0.7rem 1rem;
}

// .table-primary, .table-primary > th, .table-primary > td {
//     background-color: $selected;
// }