$template-font: #343A40;
$template-border: #6C757D80;

@import "./variables";

.no-template-card {
  background-color: $gray-120;
  border: none;
  border-radius: 0;

  h2 {
    font-size: $font-size-base * 1.125;
    font-weight: 500;
  }
  .justification {
    font-size: $font-size-base * 0.875;
    font-weight: 400;
    color: $gray-600;
  }
}

.doc-refs {
  font-weight: 500;
  font-size: $font-size-base * 0.875;
}
