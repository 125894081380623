@import "./breakpoints";

.providers-container {
  padding: 2rem;
  padding-top: 1rem;
  margin-top: 1rem;
  border: 1px solid $gray-200;
  background: $gray-0;
}

.providers-grid { 
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2%;
  grid-gap: 1rem;

  @include mxxSmall {
    grid-template-columns: repeat(1, 1fr);
  }
}

.color-text { 
  color: $primary-500;
}

.providers-dropzone {
  border-radius: 8px;
  background-color: #fff;
  padding: 2rem;
  border: 1.6px dashed $gray-300;
  cursor: pointer;
  height: 100%;
}

.dropzone-text {
  font-size: 0.875em;
  font-weight: 500;
  color: #687182;
}

.providers-tile {
  aspect-ratio: 1/ 1;
  display: flex;
  align-items: center;

  border-radius: 6px;
  border-color:  $gray-100 !important;
  color: #464f60 !important;
  display: flex;
  align-items: center;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);

  &:hover, &:focus {
    background-color: $primary-500 !important;
    color:#fff !important;

    svg {
      stroke: #fff;
    }
  }
}

.capitalize:first-letter {
  text-transform: uppercase;
}