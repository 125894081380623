.progress-circle {
  position: relative;
  width: 32px;
  height: 32px;
}

.progress-circle svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.circle-background,
.circle-progress {
  fill: none;
  stroke-width: 3;
}

.circle-background {
  stroke: #D5DBE5;
}

.circle-progress {
  stroke: #687182;
  stroke-dasharray: 50;
}

.time {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-value {
  font-size: 6px !important;
}