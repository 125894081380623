@import "./variables";


.full-height-panel {
  min-height: calc(100vh - #{$topbar-height});
  align-items: stretch;
}

.sidebar {
  background-color: $background-color;
}

.sidebar-title {
  font-weight: 700;
  color: $gray-900;
  margin: 0.8rem 0.5rem 0.5rem
}

.sidebar-item {
  padding-left: 2.5rem;
  width: 100%;
  margin-bottom: 0.8rem;

  .nav-link.active {
    background-color: $primary-0;
  }
}
