.submit-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
}

.submit-dropdown {
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;

  min-width: 220px;
  height: 36px;
  color: #464f60;
  background-color: white;
  border: 1px solid #a1a9b8;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: left;
}

.customTextInput {
  display: block;
}

.submit-card {
  border-radius: 12px;
}

.submit-back svg {
  color: $gray-300;
}

.submit-form {
  padding: 1rem;
  margin: 1rem;

  legend {
    padding: 0;
    font-size: 16px;
  }

  & > fieldset {

    margin: 0 1rem;
    padding-bottom: 0;

    & > .field-description {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      font-size: 14px;
      border-bottom: 1px solid $light-grey;
      max-width: calc(100% - 220px);

      &-infobox {
        color: $gray-600;
        background-color: $gray-120;
        display: flex;
        align-items: center;
        padding: 16px 20px;
        border-radius: 8px;
        margin-bottom: 28px;

        p {
          margin: 0 0 0 10px;
        }
      }
    }
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  div > div.col-md-12 {
    padding: 0;
  }

  input {
    border-radius: 6px;
  }

  select {
    margin-bottom: 1.5rem;
    border-radius: 6px;
    padding: 6px 12px;
    height: 100%;
  }

  textarea {
    margin-bottom: 1rem;
    border-radius: 6px;
  }
  .checkbox {
    margin-bottom: 1rem;
    margin-top: 1.5rem;

    p.field-description {
      display: none;
    }

    label {
      display: flex;
      align-items: center;

      span {
        margin-left: .5rem;
        height: 20px;
      }
    }
  }

  // Form tabs

  .nav-tabs {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .nav-item {
      cursor: pointer;

      .nav-link {
        font-size: 14px;
        background-color: #fff;
        border: none;
        padding-bottom: 1rem;
        color: #464f60;

        &:link,
        &:visited {
          color: #464f60;
        }

        &.active {
          font-weight: 700;
          border-bottom: 5px solid $primary-500;
        }
      }
    }
  }

  // Array component styles

  .submit-form-array {

    p {
      font-weight: 500;

      span.blue-star {
        color: $primary;
      } 
    }

    .array-children {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .input-container {
        width: 80%;
      }
  
      button {
        margin-left: 1rem;
        margin-bottom: 1rem;
        background-color: #FFF;
        border: 1px solid $gray-400;
        border-radius: 6px;
        height: calc(1.5em + 0.75rem + 2px);
        width: 36px;
  
        &:disabled {
          background-color: #F7F9FC;
        }
  
        svg {
          fill: #868FA0;
          height: 75%;
          width: 75%;
        }
      }
    }

    .add-value {
      display: flex;
      align-items: center;
      background-color: #FFF;
      border: 1px solid $gray-400;
      border-radius: 6px;
      padding: .5rem 1rem;
      margin: 1rem 0;

      span {
        margin-bottom: 0;
        white-space: nowrap;
        height: 20px;
        font-weight: 500;
      }

      svg {
        fill: #868FA0;
        height: 22px;
        width: 22px;
        margin-right: .5rem;
      }
    }
    
  }

  .form-buttons {
    display: flex;
    justify-content: right;
    margin-right: 2rem;

    a {
      color: $gray-700;
      background-color: #FFF;
      border: 1px solid $gray-400;
      margin-right: 20px;
      border-radius: 6px;
      padding: 6px 25px;
      font-weight: 500;

      &:hover {
        color: $gray-700;
        background-color: #FFF;
        border: 1px solid $gray-400;
      }
    }

    button {
      padding: 6px 25px;
      font-weight: 500;
    }
  }

 &-modal-header {
  padding: 1.5rem 2rem;
    h3 {
      font-size: 20px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0;
    }
 }

 &-modal-footer {
  button {
    width: 50px;
  }
 }

  fieldset fieldset {
    margin: 0 0 1.5rem;
    padding: 0;

    & .field-description {
      display: none;
    }
  }

  .horizontal {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 16px;
    flex: 1;

    .horizontal > :last-child {
      margin-right: 0; 
    }

    & select {
      display: inline;
      width: auto;
      margin: 0.7rem 1.5rem 0 0.5rem;
    }
  }

}

.submit-form fieldset fieldset{
  display: flex;
  width: 100%;
  gap: 24px;
}

.icon {
  display: flex;
  width: calc(100% - 64px);
  justify-content: flex-end;
  position: absolute;
  padding-top: 16px;
  margin-top: 16px;
  img {
    height: 45px;
  }
}
