@import "./variables";

.navbar-brand {
  font-size: 16px;
  font-weight: 700;
  padding-left: 10px;
  margin-right: 0;
  span {
    padding: 10px;
  }
}

.navbar {
  z-index: 10;
  height: $topbar-height;
  margin: 0;
  padding: 0;
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.05);

  img.logo {
    width: 65px;
  }
}

.navbar-collapse,
.navbar-nav,
.nav-link {
  height: 100%;
}

.navbar .nav-item {
  font-size: 16px;
  font-weight: 500;
  width: 130px;
}

.navbar .dropdown-menu {
  border-radius: 0 0 6px 6px;
  box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.08);

  & .dropdown-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
}

.login {
  font-size: medium;
  padding-right: 25px;
}

.navbar-problem-button {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  margin: auto 0;
}