/*!******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url("../../assets/fonts/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url("../../assets/fonts/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../../assets/fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url("../../assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url("../../assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url("../../assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url("../../assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url("../../assets/fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url("../../assets/fonts/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}

@import "./variables";

@import "~react-table/react-table.css";
@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";

@import "~bootstrap/scss/bootstrap";
@import "./customFileWidget.scss";
@import "./topbar.scss";
@import "./filters.scss";
@import "./table.scss";
@import "./pagination.scss";
@import "./area.scss";
@import "./submit.scss";
@import "./columns.scss";
@import "./taskDetails.scss";
@import "./templates.scss";
@import "./sidebar.scss";
@import "./landningPage.scss";

/*** Global ***/


html {
  overflow-y: auto;
  font-size: 16px;
}

body {
  height: 100vh;
  overflow-x: hidden;
}

#root, div[data-testid="app-test"], .container-fluid, .main-content {
  height: 100%;
}

// Custom scrollbar
::-webkit-scrollbar {
  width: 0.75rem;
}

::-webkit-scrollbar-track {
  background: var(--gray-100, #D5DBE5);
  width: 0.75rem;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--gray-300, #A1A9B8);
  border-radius: 99px;
}

.bold {
  font-family: $font-family-sans-serif-bold;
}

.s2-text {
  font-size: $font-size-base * 0.875;
  font-weight: 500;
  color: $gray-700;
}

.b3-text {
  font-size: $font-size-base * 0.875;
  font-weight: 600;
  color: $gray-600;
}

.b4-text {
  font-size: $font-size-base * 0.875;
  font-weight: 400;
  color: $gray-700;
}

.small-text {
  font-size: $font-size-base * 0.75;
  font-weight: 500;
  color: $gray-400;
}

.text-transparent {
  color: rgba(0, 0, 0, 0);
}

.cursor-pointer {
  cursor: pointer;
}

.mw-50 {
  max-width: 50%;
}

.mh-75 {
  max-height: 75%;
}

ul {
  padding-inline-start: 20px;
  margin-bottom: 0.8rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.btn {
  font-family: $headings-font-family;
}

.title {
  margin-right: 1em;
}

.iframe-modal-footer {
  justify-content: space-between;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 80vh;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.iframe-modal {
  width: 90vw;
  max-width: 90vw;
}

.center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.center-vertical {
  display: flex !important;
  align-items: center !important;
}

.btn {
  text-align: center;
  padding: 5px 10px 5px 10px;
}

.btn-secondary {
  color: #106bef;
  border-color: #106bef;
  background-color: white;
}

.btn-secondary:not(.disabled):hover {
  color: #fff;
  background-color: #106bef;
  border-color: #106bef;
}

/*** Custom icons ***/

.custom-icon {
  height: 16px;
  width: 16px;
  background: no-repeat center;
  background-size: contain;
  transition-delay: 0.07s;
  display: inline-block;
  vertical-align: middle;
}

.custom-icon.big {
  height: 20px;
  width: 20px;
}

button:not(.disabled):hover .custom-icon {
  filter: invert(100%);
}

/*** Dashboard elements ***/

#dashboard .row + .row {
  margin-top: 14px;
}

.nested-area {
  border: 1px solid gray;
  height: 100%;
  padding: 10px;
  margin: 10px;
  font-size: 9pt;
}

.cluster-area {
  display: flex;
  padding: 30px;
  align-items: flex-end;
  justify-content: center;
}

.refresh-feature {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.refresh-button {
  border-width: 0;
}

.refresh-feature span {
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  color: #333333;
}

.refresh-feature input {
  font-weight: bold;
  font-size: 14px;
  margin-top: 8px !important;
}

.no-data-overlay {
  text-align: center;
  vertical-align: middle;
  line-height: 20vh;
}

.droppable-list.dragging-over .draggable-item {
  background: $gray-200;
}

.draggable-item {
  user-select: none;
}

.droppable-list.dragging-over .draggable-item.dragged {
  background: rgba(242, 95, 95, 0.69);
}

.draggable-item.dragged {
  display: table;
}

.order-column,
.visibility-column {
  text-align: center;
}

.vis-thumbnail {
  max-width: 100%;
}

.-header label:not(.text-transparent) input[type="checkbox"] + * {
  font-size: 0.825rem;
  &:before {
    width: 14px;
    height: 14px;
  }
}

#charts > .row > .col {
  margin: 1.5em 0;
  padding: 0;
}

.chart-box {
  width: 62%;
}

.chart-box-line {
  height: 40vh;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-tooltip {
  background: white;
  opacity: 0.9;
  padding: 9px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.65);
}

.tooltip[x-placement="right"] .arrow::before,
.tooltip[x-placement="left"] .arrow::before {
  display: none;
}

#filters-header .remove-filter {
  margin-right: -1em;
}

.submit-time-header .remove-filter {
  margin-top: -1.2em;
}

.modify-time-header .remove-filter {
  margin-top: -0.2em;
}

.remove-filter {
  margin-right: -2.27em;
}

/*** All modals ***/
body.modal-open {
  padding-right: 0 !important;
}

.modal-content {
  box-shadow: 0px 11px 39px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
}

.modal-content {
  border: none;
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    padding: 0 1rem 1rem;
  }
}

.badge.wrap {
  white-space: normal;
}

/*** Datetime picker ***/

@import "~react-datetime/css/react-datetime.css";

.rdtOpen .rdtPicker {
  position: relative;
  margin-left: -1.5em;
}

.task-fields-list {
  word-break: break-all;
}

/*** Show JSON modal ***/

.pre-scrollable {
  max-height: 70vh;
}

#text-file-icon {
  margin-top: -3px;
}

/*** Popover closing X ***/

.popover-close {
  margin-top: -0.2rem;
}

/*** Task actions ***/

.queue-mover {
  float: left;
}

.task-canceler {
  float: right;
  margin-right: 1em;
}

.task-remover {
  float: right;
}

/*** Notifications ***/

.redux-toastr .toastr {
  border-radius: 0;
  .rrt-title {
    font-family: $headings-font-family;
    font-size: 1.1em;
  }
  .rrt-text {
    font-family: $font-family-sans-serif-bold;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  &.rrt-info,
  &.rrt-success,
  &.rrt-warning,
  &.rrt-error,
  & button {
    color: white;
  }
  &.rrt-error {
    background-color: $danger;
  }
  &.rrt-success {
    background-color: $success;
  }
  &.rrt-warning {
    background-color: $dark;
  }
  &.rrt-info {
    background-color: $info;
  }
  .close-toastr {
    opacity: 0.6;
  }
}

/*** Form ***/
.required {
  color: rgb(94, 90, 219);
  margin-left: 4px;
}

fieldset {
  margin: 1rem;
  padding: 1rem;
}

legend {
  width: auto;
  margin: 0 0 -0.5rem 0;
  padding: 0 1rem;
  font-size: 1.2rem;
}

.field-description {
  color: gray;
  margin-bottom: 0;
  font-size: 0.9rem;
}

.form-group > .field-description {
  float: right;
}

.array-item-toolbox {
  margin-top: 2rem;
}

.form-group.field.field-object .form-group.field {
  // background-color: #ededed;
  padding: 6px;
  margin: 8px;
}

iframe{
  width: 100%;
}

.tooltip.show.bs-tooltip-auto .arrow {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.no-scroll {
  overflow: hidden;
}

.horizontal {
  margin: 10px 20px 0 0;
}